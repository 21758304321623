import React from 'react';
import './DeliverySiteHeader.css';

const DeliverySiteHeader = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = isFirstLoad ? "" : props.loadingMessage;
  const theDeliverySite = props.theDeliverySite;

  // determine statuses and other info
  const isDeliverySiteDefined = theDeliverySite !== undefined;
  let locationCode = isDeliverySiteDefined ? theDeliverySite.locationCode : undefined;

  // render either that we're loading data (if loaded for the first time), or the delivery site, or that the delivery site does not exist
  let headerFragment = isDeliverySiteDefined
    ? <>
      <div className="DeliverySiteHeader-title">Delivery Site {locationCode}</div>
      <div className="DeliverySiteHeader-loading-message">{loadingMessage}</div>
    </>
    : isFirstLoad
      ? // this is the very first load
      <div className="notification">Loading ...</div>
      : // this is a subsequent load
      <div className="error">Delivery site does not exist.</div>

  // render
  return (<>
    <div className="DeliverySiteHeader">
      <div className="App-wrapper DeliverySiteHeader-wrapper">
        {headerFragment}
      </div>
    </div>
  </>);
}

export default DeliverySiteHeader;